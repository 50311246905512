/* @import 'antd/dist/reset.css'; */
/* @import 'country-flag/sprite-flags-24x24.css';
@import 'ql-editor.css'; */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;200;300;400;500;600;700;800&display=swap');

/* color pallete https://flatuicolors.com/palette/us */

@font-face {
  font-family: 'MICR'; 
  src: url('../../../public/fonts/micr.ttf') format('truetype'); 
}

@font-face {
  font-family: 'SignatureStyle1'; 
  src: url('../../../public/fonts/abuget.ttf') format('truetype'); 
}

@font-face {
  font-family: 'SignatureStyle2'; 
  src: url('../../../public/fonts/thesignature.ttf') format('truetype'); 
}

body{
  font-weight: 300; 
  font-family: 'Be Vietnam Pro', sans-serif;
  -webkit-font-smoothing: antialiased !important; 
  -moz-osx-font-smoothing: grayscale !important; 
  text-rendering: optimizeLegibility !important; 
}


.micr {
  font-family: 'MICR' !important; 
}
.signature-style-one {
  font-family: 'SignatureStyle1' !important; 
}
.signature-style-two {
  font-family: 'SignatureStyle2' !important; 
}
.success {
  color: #00b894;
  
}
.bg-success {
  background-color: #00b894;
}
.bg-success-light {
  background-color: #d4fdf0;
  border-color: #00b894;
  
}
.warning {
color: #fdcb6e;
}
.bg-warning {
  background-color: #fdcb6e;
}
.bg-warning-light {
  background-color: #fff7d4 ; 
  border-color: #fdcb6e; 
}
.info{
    color: #0984e3;
}

.bg-blue{
background-color: #2980b9;;
}

.bg-info{
  background-color: #0984e3;
}
.bg-info-light {
  background-color: #d4f1fd;
  border-color: #74b9ff;
}
.gray {
  color: #636e72;
}
.bg-gray {
  background: #636e72;
}
.bg-gray-light {
  background-color: #dfe6e9;
  border-color: #636e72;
}
.error {
  color: #d63031;
}
.bg-error {
  background-color: #d63031;
}
.bg-error-light {
  background-color: #ffd4d4;
  border-color: #d63031;
}

.orange {
  color: #e17055;
}
.bg-orange {
  background-color: #e17055;
}
.bg-orange-light {
  background-color: #fde6d4;
  border-color: #e17055;
}

/* Button styles with updated colors and sizes */
.button-green {
  background-color: #00b894;
  color: #fff;
  border: none;
  transition: all 0.3s ease;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

.button-green:hover {
  background-color: #33c2aa !important;
  color: #fff !important;
  border-color: #00b894 !important;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 184, 148, 0.3);
  transform: translateY(-1px);
}

/* Background Light Gray */
.background-lightGray {
  background-color: #b2bec3;
  transition: all 0.3s ease;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

.background-lightGray:hover {
  background-color: #d1d6d8 !important;
  box-shadow: 0 2px 4px rgba(178, 190, 195, 0.3);
  transform: translateY(-1px);
}

.button-red {
  background-color: #ff4757; /* Updated red color */
  color: #fff;
  border: none;
  transition: all 0.3s ease;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

.button-red:hover {
  background-color: #ff6b81 !important; /* Lighter red */
  color: #fff !important;
  border-color: #fff !important;
  border: none;
  box-shadow: 0 2px 4px rgba(255, 71, 87, 0.3);
  transform: translateY(-1px);
}

.button-blue {
  background-color: #2980B9; /* Updated blue color */
  color: #fff;
  border: none;
  transition: all 0.3s ease;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

.button-blue:hover {
  background-color: #54a0ff !important; /* Lighter blue */
  color: #fff !important;
  border-color: #fff !important;
  border: none;
  box-shadow: 0 2px 4px rgba(30, 144, 255, 0.3);
  transform: translateY(-1px);
}

/* Button Gray */
.button-gray {
  background-color: #636e72;
  color: #fff;
  border: none;
  transition: all 0.3s ease;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

.button-gray:hover {
  background-color: #83898b !important;
  color: #fff !important;
  border-color: #fff !important;
  border: none;
  box-shadow: 0 2px 4px rgba(99, 110, 114, 0.3);
  transform: translateY(-1px);
}

/* Button Yellow */
.button-yellow {
  background-color: #feca57; /* Updated yellow color */
  color: #fff; /* Darker text for better contrast */
  border: none;
  transition: all 0.3s ease;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

.button-yellow:hover {
  background-color: #ffdb7d !important;
  color: #333 !important;
  border-color: #fdcb6e !important;
  border: none;
  box-shadow: 0 2px 4px rgba(254, 202, 87, 0.3);
  transform: translateY(-1px);
}

.button-orange {
  background-color: #ff7f50; /* Updated orange color */
  color: #fff;
  border: none;
  transition: all 0.3s ease;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

.button-orange:hover {
  background-color: #ff9f7f !important;
  color: #fff !important;
  border-color: #e17055 !important;
  border: none;
  box-shadow: 0 2px 4px rgba(255, 127, 80, 0.3);
  transform: translateY(-1px);
}

/* Button Navy Blue */
.button-navy {
  background-color: #2c3e50; /* Updated navy color */
  color: #fff;
  border: none;
  transition: all 0.3s ease;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

.button-navy:hover {
  background-color: #3c5876 !important;
  color: #fff !important;
  border-color: #34495e !important;
  border: none;
  box-shadow: 0 2px 4px rgba(44, 62, 80, 0.3);
  transform: translateY(-1px);
}

/* Button Light Grey */
.button-light-gray {
  background-color: #ecf0f1; /* Updated light gray color */
  color: #333;
  border: none;
  transition: all 0.3s ease;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

.button-light-gray:hover {
  background-color: #d5dbdb !important;
  color: #333 !important;
  border-color: #dfe6e9 !important;
  border: none;
  box-shadow: 0 2px 4px rgba(236, 240, 241, 0.3);
  transform: translateY(-1px);
}

/* Button Dark Grey */
.button-dark-gray {
  background-color: #2d3436;
  color: #fff;
  border: none;
  transition: all 0.3s ease;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
}

.button-dark-gray:hover {
  background-color: #3d4749 !important;
  color: #fff !important;
  border-color: #2d3436 !important;
  border: none;
  box-shadow: 0 2px 4px rgba(45, 52, 54, 0.3);
  transform: translateY(-1px);
}

/* Small button variant */
.button-sm {
  padding: 4px 12px;
  font-size: 12px;
}

/* Large button variant */
.button-lg {
  padding: 12px 20px;
  font-size: 16px;
}

/* Rounded button variant */
.button-rounded {
  border-radius: 50px;
}

/* table{
  font-family: 'Montserrat' !important;
} */

table tr td{
    font-family: 'Courier' !important;

}

.ant-popconfirm-message .anticon-exclamation-circle{
  margin-top:5px;
}

.color-theme{
  background-color:#2980b9;
}


.main-menu-div{
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  background-color: #2980b9;
}

.mobile-main-menu-div{
  height: 64px;
  width: 100%;
  text-align: center;
  background-color: #2980b9 !important;

}
.content-container{
  margin: 0px 16px;
  min-height: 500px;
  overflow: auto;
  
}

.ant-menu-title-content {

}

.ant-card{
  box-shadow: 1px 1px 5px 0px rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}

.shadow{
  box-shadow: 1px 1px 5px 0px rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}

.shadow-square{
  box-shadow: 1px 1px 5px 0px rgba(128, 128, 128, 0.5);
  /* border-radius: 10px; */
}


.ant-menu-item-selected::after {
  border-right: none; /* Remove default border for a cleaner look */
}

.ant-menu-item{
  /* padding-left:12px !important; */
  /* font-weight: 100 !important; */

}

.ant-menu-item-selected{
  /* background: linear-gradient(90deg, #2980b9, #1976d2, #64b5f6);  */
  background-color: white !important;
  box-shadow: 0px 0px 5px 0px rgba(128, 128, 128, 0.5);
  color: #0984e3 !important;
  border-radius: 10px !important;;
  font-weight: 600 !important;

}

.ant-layout-sider-children{

}

.ant-layout-sider {

}

.ant-menu-light{
  background-color: #dfe6e9 !important;
}

table{
}

.ant-menu {
}

.top-header{
    padding: 0;
    background-color: #2980b9 !important;
}

.ant-btn-circle{
}

.logo-title{
  margin-left:-5;
  padding-left:0;
  font-family: 'Courier';
  font-size: 24px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  color: white;

}

.button-group .desktop-only {
  padding-top: 4px;
  display: none;
}

@media (min-width: 768px) {
  .button-group .desktop-only {
    display: inline-block; /* Or block, depending on how you want it displayed */
  }
}

@media (max-width: 768px) {
  .scrollable-table {
    padding: 10px;
  }
}

.ant-table-wrapper {
  overflow-x: auto;
  max-height: 100%;
  /* padding-right: 16px; */
}

/* Ensure Card and Content boundaries */
.content-container {
  /* padding: 24px; */
  overflow: hidden;
}

/* Add responsive styling for smaller screens */
@media (max-width: 768px) {
  .scrollable-table {
    padding: 10px;
  }
}

.scrollable-table {
  overflow: auto !important;  /* Scroll on both axes */
  max-height: 70vh; /* Adjust height as needed */
  margin-bottom: 0; /* Ensure no overflow margin */
  width: 100%;
  /* padding: 10px; */
}

/* Responsive video container */
.responsive-video-container {
  position: relative;
  width: 100%; /* Take full width of the parent */
  padding-top: 56.25%; /* Maintain a 16:9 aspect ratio */
  overflow: hidden; /* Ensure no bleeding */
  border-radius: 8px; /* Optional: Add rounded corners for aesthetics */
}

.responsive-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Scale iframe to fill the container */
  height: 100%; /* Scale iframe to fill the container */
  border: none; /* Remove default iframe border */
}

/* Adjust Card content on mobile */
@media (max-width: 768px) {
  .responsive-video-container {
    padding-top: 56.25%; /* Maintain aspect ratio on smaller screens */
  }

  .info {
    font-size: 1rem; /* Adjust font size if necessary */
  }
}

.progress-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.progress-step {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
}

.progress-step .arrow {
  background-color: #e0e0e0;
  color: #595959;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: bold;
  clip-path: polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%);
  text-align: center;
  z-index: 2;
  border-radius: 6px;
}

.progress-step.active .arrow {
  background-color: #1890ff;
  color: white;
}

.progress-step.bg-red .arrow {
  background-color: #ff4d4f;
  color: white;
}

.progress-step.inactive .arrow {
  background-color: #e0e0e0;
  color: #595959;
}

.step-line {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  height: 2px;
  width: 100%;
  transform: translateY(-50%);
  background-color: #e0e0e0; /* Default to gray */
}

.step-line.blue {
  background-color: #1890ff;
}

.step-line.gray {
  background-color: #e0e0e0;
}

.progress-step:last-child .step-line {
  background-color: transparent;
}

.status-text {
  position: relative;
  z-index: 2;
}



:root {
  /* Primary colors */
  --primary-color: #1890ff;
  --success-color: #52c41a;
  --warning-color: #faad14;
  --error-color: #f5222d;
  --info-color: #1890ff;
  
  /* Background colors */
  --bg-light: #f5f5f5;
  --bg-success-light: #f6ffed;
  --bg-error-light: #fff1f0;
  --bg-warning-light: #fffbe6;
  --bg-info-light: #e6f7ff;
  
  /* Text colors */
  --text-primary: rgba(0, 0, 0, 0.85);
  --text-secondary: rgba(0, 0, 0, 0.65);
  --text-disabled: rgba(0, 0, 0, 0.25);
  
  /* Border radius */
  --border-radius-base: 4px;
  --border-radius-sm: 2px;
  
  /* Transitions */
  --transition-base: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Then use these variables in your component styles */
.action-btn:hover {
  color: var(--primary-color);
  background-color: var(--bg-info-light);
  transition: var(--transition-base);
}

.status-tag {
  border-radius: var(--border-radius-base);
  transition: var(--transition-base);
}

.cleared-row {
  background-color: var(--bg-success-light);
}
/* For status tags */
.status-tag {
  transition: all 0.2s ease;
}
.status-tag:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* For action buttons */
.action-btn {
  transition: all 0.2s ease;
}
.action-btn:hover {
  transform: scale(1.1);
}

/* For row hover */
.ant-table-tbody > tr:hover {
  transition: background-color 0.3s ease;
}

/* For batch actions bar */
.batch-actions-bar {
  animation: slideDown 0.3s ease;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.email-module {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.email-form {
  width: 100%;
}

.editor-toolbar {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.editor-toolbar button {
  margin-right: 0.5rem;
}


